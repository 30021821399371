import React from "react";

function CollabWork() {
  return(
    <>
      <div className="more-services-container section-container collab-container"  id="collab" name="collab">
          <div className="container">
              
              <div className="row">
                  <div className="col more-services section-description wow fadeIn">
                      <h2>Collab Work</h2>
                      <div className="divider-1 wow fadeInUp"><span></span></div>
                  </div>
              </div>
              
              <div className="row">
                  <div className="col-md-6 more-services-box wow fadeInLeft">
                      <div className="row">
                          <div className="col-md-3">
                              <div className="more-services-box-icon">
                                  <i className="fas fa-tshirt"></i>
                              </div>
                          </div>
                          <div className="col-md-9">
                              <h3>Fashion Clothing</h3>
                              <p>
                                I really enjoy doing collab pictures. So, if you are blogger from any common store (check my sponsors, please), and would like to do some collab with me, please contact me through Facebook or inworld (ironytai Resident). You can send me instant message, or notecard. I will answer you as soon as possible!
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-6 more-services-box wow fadeInLeft">
                      <div className="row">
                          <div className="col-md-3">
                              <div className="more-services-box-icon">
                                  <i className="fas fa-camera"></i>
                              </div>
                          </div>
                          <div className="col-md-9">
                              <h3>Stylish Poses</h3>
                              <p>
                              If you're blogging for a pose store and need someone for couple/friends poses, I can also do this kind of collab if it's from your interest! Please contact me inworld so we can work out the best way to do it!
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          

          </div>
        </div>
  </>
  )
}

export default CollabWork;